import { FC } from 'react'
import styled from 'styled-components'
import { ABTestGroupCaseEnum, ABTestGroupCodeEnum } from '@/apis/rest/generated/types'
import { Image } from '@/components/common/images/Image'
import { Z_INDEXES } from '@/constants/legacy/constLayout'
import ROUTES from '@/constants/legacy/constRoutes'
import { PageSizeEnum } from '@/constants/page-size.enum'
import { useHomeTracker } from '@/containers/event-tracker/home.event-tracker'
import { useCustomRouter, useRecentlyProduct } from '@/containers/hooks'
import { useAbTest } from '@/containers/hooks/useAbTest'
import { getImageUrl } from '@/utils/utilURL'

type ButtonRecentlyItemProps = {
  showTopButton: boolean
}

const ButtonRecentlyItem: FC<ButtonRecentlyItemProps> = ({ showTopButton }) => {
  const { push } = useCustomRouter()
  const { trackClickHomeFloatingButtonEvent } = useHomeTracker()
  const { ab } = useAbTest({
    abKey: ABTestGroupCaseEnum.REDUCE_HOME_MAIN_TOP_AREA,
    pathname: ROUTES.INDEX,
  })
  const { buttonThumbnail } = useRecentlyProduct({
    length: PageSizeEnum.PageSize1,
    isFloatingButton: true,
  })
  const routeByAb = ab?.code === ABTestGroupCodeEnum.A ? ROUTES.MYPAGE.RECENTLY_LIST : ROUTES.MYPAGE.FAVORITE_LIST

  const handleClickButton = () => {
    trackClickHomeFloatingButtonEvent()
    push(routeByAb)
  }

  if (!buttonThumbnail || !ab?.code) {
    return null
  }

  return (
    <>
      <StyledButton onClick={handleClickButton} active={showTopButton} ab={ab?.code}>
        <Image width="100%" height="100%" src={getImageUrl(buttonThumbnail) as string} alt="productThumbnail" />
      </StyledButton>
    </>
  )
}

export default ButtonRecentlyItem

const StyledButton = styled.div<{ active: boolean; ab?: ABTestGroupCodeEnum }>`
  z-index: ${Z_INDEXES.SCROLL_TOP_BUTTON};
  transform: ${({ active }) => (active ? 'translate(0, 0)' : 'translate(0, 4.8rem)')};
  width: 4rem;
  height: 4rem;
  background-color: ${(props) => props.theme.color.grayWhite};
  pointer-events: auto;
  overflow: hidden;
  box-shadow: 0.2rem 0.4rem 0.4rem rgba(0, 0, 0, 0.04);
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.65, 0, 0.35, 1) 0.1s;
  border: ${({
    theme: {
      color: { blue500 },
    },
    ab,
  }) => (ab === ABTestGroupCodeEnum.A ? `0.1rem solid ${blue500}` : `0.2rem solid ${blue500}`)};
  border-radius: 50%;
`
