import { ImageTypeBannerResponseDto } from '@/apis/rest/generated/types'
import { Image } from '@/components/common'
import { LinkButton } from '@/components/common/buttons'
import { CarouselBannerNameEnum } from '@/constants/carousel-banner-name.enum'
import { CAROUSEL_SCREEN_RATIO } from '@/constants/legacy/constLayout'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import useMembershipEventTracker from '@/containers/event-tracker/membership.event-tracker'
import { useIntersectionObserver } from '@/containers/hooks'
import { appendQueryStringToPathname } from '@/utils/appendQueryStringToPathname'
import { convertBannerParamsNew } from '@/utils/event-tracker/convertParams/convertBannerParamsNew'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'

type CarouselBannerProps = {
  banner: ImageTypeBannerResponseDto
  index: number
}

const bannerInlineMargin = '0px'

export const CarouselBanner = ({ banner, index }: CarouselBannerProps) => {
  const { triggerCustomEvent } = useTracker()
  const { trackClickHomeBannerMembership } = useMembershipEventTracker()

  const eventTracker = (n: HTMLDivElement) => {
    /** @desc slick 동작 : 내용이 같은 div를 복제함 -> 동일한 index를 갖는 clone된 div(배너)는 트래킹하지 않아야함, 이때 Ancestor Element 의 class 이름을 확인해야해서 closest 사용 */
    if (n.closest('.slick-cloned')) {
      return
    }

    triggerCustomEvent(FitpetMallEventEnum.ViewHomeBanner, convertBannerParamsNew(banner, index))
  }

  const { elementRef } = useIntersectionObserver(eventTracker, { rootMargin: bannerInlineMargin, threshold: 1 })

  const handleClick = () => {
    if (banner.title === CarouselBannerNameEnum.Membership) {
      trackClickHomeBannerMembership()
    }
    triggerCustomEvent(FitpetMallEventEnum.ClickHomeBanner, convertBannerParamsNew(banner, index))
  }

  return (
    <LinkButton
      url={appendQueryStringToPathname({
        pathname: banner.link,
        param: 'eventsale_location',
        value: 'home_banner',
      })}
      setAnchorHref={false}
      onClick={handleClick}
    >
      <div ref={elementRef}>
        <Image
          src={banner.imageUrl}
          alt="home-carousel-banner"
          layout="responsive"
          width={CAROUSEL_SCREEN_RATIO.WIDTH}
          height={CAROUSEL_SCREEN_RATIO.HEIGHT}
          objectFit="fill"
        />
      </div>
    </LinkButton>
  )
}
