import styled, { useTheme } from 'styled-components'
import { ProductsCategoryResponseDtoAllOfChildrenItem, ProductsCategoryScheme } from '@/apis/rest/generated/types'
import { Image } from '@/components/common'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import {
  CategoryItem,
  RecommendCategoryRootItem,
  RecommendCategorySubItem,
} from '@/components/domains/home/new/home-section-list/category-section/types/category-section.type'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import { FITPETMALL_HOST } from '@/constants/legacy/constEnv'
import ROUTES from '@/constants/legacy/constRoutes'
import { MembershipStatusEnum } from '@/constants/membership-status.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter, usePetType } from '@/containers/hooks'
import { UserContainer } from '@/containers/users/UserContainer'
import { Bridge } from '@/utils/bridge/bridge'
import appBridgeProvider from '@/utils/utilBridge'
import { isInApp } from '@/utils/utilCommon'

const isCategoryQuickMenuItem = (category: any): category is RecommendCategorySubItem => {
  return typeof category?.webLink === 'string'
}

type CategoryItemListProps = {
  subCategory: RecommendCategorySubItem | ProductsCategoryScheme
  selectedRootCategory: CategoryItem
}

export const CategoryItemList = ({ subCategory, selectedRootCategory }: CategoryItemListProps) => {
  if (isCategoryQuickMenuItem(subCategory)) {
    return (
      <RecommendCategoryItemList
        subCategory={subCategory}
        selectedRootCategory={selectedRootCategory as RecommendCategoryRootItem}
      />
    )
  } else {
    return (
      <NormalCategoryItemList
        subCategory={subCategory}
        selectedRootCategory={selectedRootCategory as ProductsCategoryResponseDtoAllOfChildrenItem}
      />
    )
  }
}

const RecommendCategoryItemList = ({
  subCategory,
  selectedRootCategory,
}: {
  subCategory: RecommendCategorySubItem
  selectedRootCategory: RecommendCategoryRootItem
}) => {
  const { me } = UserContainer.useContainer()
  const tracker = useTracker()
  const { id: categoryId, name: categoryName } = subCategory
  const { push } = useCustomRouter()
  const { color } = useTheme()

  const handleClickItem = ({ webLink, appLink }: { webLink: string; appLink: string }) => {
    if (isInApp() && appLink) {
      const deepLink = appLink.includes('play') ? 'play' : 'hospital'
      if (deepLink === 'play') {
        appBridgeProvider((bridge: Bridge) => {
          bridge.goDeepLink(deepLink, 'main')
        })
      }
      if (deepLink === 'hospital') {
        appBridgeProvider((bridge: Bridge) => {
          bridge.goDeepLink(deepLink, '/home')
        })
      }
    } else {
      const link = webLink.replace(FITPETMALL_HOST, '')
      /**
       * 핏펫플러스를 클릭했을 때 핏펫플러스에 이미 가압한 사용자는 핏펫플러스 홈으로 이동
       */
      if (
        link.includes('membership') &&
        (me?.membershipStatus === MembershipStatusEnum.Owner || me?.membershipStatus === MembershipStatusEnum.Family)
      ) {
        push(ROUTES.MEMBERSHIP.INDEX)
        return
      }

      push(link)
    }
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeCategoryItem, {
      categoryId,
      categoryName,
      categoryTypeName: selectedRootCategory.name,
    })
  }

  // 가입 이력이 있는 경우
  // 현재 멤버십이거나, 멤버십 이력이 있는 경우
  const hasMembershipHistory =
    me?.membershipStatus === MembershipStatusEnum.Owner ||
    me?.membershipStatus === MembershipStatusEnum.Family ||
    me?.hasMembership

  if (!subCategory.imageUrl) return null

  return (
    <StyledCol>
      <StyledRecommendCategoryButton
        type="button"
        onClick={() => handleClickItem({ webLink: subCategory.webLink, appLink: subCategory.appLink })}
      >
        <StyledItem>
          <StyledImageRow>
            {subCategory.name === '핏펫플러스' && !hasMembershipHistory && (
              <StyledTooltipContent>첫달무료</StyledTooltipContent>
            )}
            <Image src={subCategory.imageUrl} layout="intrinsic" width="100%" height="100%" objectFit="cover" alt="" />
          </StyledImageRow>
        </StyledItem>
        <StyledTextRow>
          <Typo variant={TypoVariant.Body5Regular} color={color.gray900}>
            {subCategory.name}
          </Typo>
        </StyledTextRow>
      </StyledRecommendCategoryButton>
    </StyledCol>
  )
}

const StyledRecommendCategoryButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
`

const StyledTooltipContent = styled.div`
  z-index: 100;
  position: absolute;
  top: -10px;
  background-color: rgba(31, 41, 55, 0.8);
  color: white;
  padding: 0.1rem 0.5rem;
  border-radius: 0.8rem;
  font-size: 0.9rem;
  font-weight: 800;
  white-space: nowrap;

  &::after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 12px;
    height: 6px;
    background-color: rgba(31, 41, 55, 0.8);
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

const NormalCategoryItemList = ({
  subCategory,
  selectedRootCategory,
}: {
  subCategory: ProductsCategoryScheme
  selectedRootCategory: ProductsCategoryResponseDtoAllOfChildrenItem
}) => {
  const tracker = useTracker()
  const petType = usePetType().getPetType()
  const { id: categoryId, name: categoryName } = subCategory
  const { push } = useCustomRouter()
  const { color } = useTheme()

  const handleClickItem = () => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeCategoryItem, {
      categoryId,
      categoryName: categoryName.replace(/~/g, ','),
      categoryTypeName: selectedRootCategory.name,
    })

    push({
      pathname: `/category/[rootCategorySlug]/[subCategorySlug]`,
      query: {
        rootCategorySlug: selectedRootCategory.slug,
        subCategorySlug: subCategory.slug,
        petType,
        trackingCategoryId: categoryId,
      },
    })
  }

  if (!subCategory.listImage) return null

  return (
    <StyledCol>
      <StyledLinkButton role="button" onClick={handleClickItem}>
        <StyledItem>
          <StyledImageRow>
            {subCategory.slug !== 'all' && (
              <Image
                src={subCategory.listImage}
                layout="intrinsic"
                width="100%"
                height="100%"
                objectFit="cover"
                alt=""
              />
            )}
            {subCategory.slug === 'all' && <FlatIcon type={IconNameEnum.IcAddSmall} size={IconSizeEnum.Size20} />}
          </StyledImageRow>
        </StyledItem>
        <StyledTextRow>
          <Typo variant={TypoVariant.Body5Regular} color={color.gray900}>
            {subCategory.name}
          </Typo>
        </StyledTextRow>
      </StyledLinkButton>
    </StyledCol>
  )
}

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledLinkButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
`

const StyledItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.gray50};
  position: relative;
`

const StyledImageRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const StyledTextRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.4rem;

  div {
    text-align: center;
    letter-spacing: -0.02em;
  }
`
