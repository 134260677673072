import { useEffect, useMemo, useRef, useState } from 'react'
import { throttle } from 'lodash'
import styled from 'styled-components'
import { ButtonScrollTop } from '@/components/common/buttons'
import ButtonRecentlyItem from '@/components/common/buttons/ButtonRecentlyItem'
import { FIXED_HEIGHT, SCREEN_MEDIA_QUERY, SCREEN_SIZES, Z_INDEXES } from '@/constants/legacy/constLayout'
import ROUTES from '@/constants/legacy/constRoutes'
import { useCustomRouter } from '@/containers/hooks'

export const HomeSectionFloatingButtonA = () => {
  const { pathname } = useCustomRouter()
  const [isApp, isAppToggle] = useState<boolean>(true)
  const [showTopButton, setShowTopButton] = useState<boolean>(false)
  const lastScrollRef = useRef<number>(0)

  useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  // 스크롤 위치에 따라 스크롤탑, 플로팅 버튼 분기
  const onScroll = useMemo(
    () =>
      throttle(() => {
        const { pageYOffset } = window
        setShowTopButton(pageYOffset > 100 && lastScrollRef.current > pageYOffset)
        lastScrollRef.current = pageYOffset
      }, 100),
    [showTopButton]
  )

  const getBottom = () => {
    if (
      pathname === ROUTES.EVENTS_100_DEAL ||
      pathname === ROUTES.PRODUCTS.DETAIL ||
      pathname === '/products-new/[productId]'
    ) {
      return FIXED_HEIGHT.PRODUCT_ORDER_BUTTON + 16
    }
    if (isApp) {
      return 16
    }
    return FIXED_HEIGHT.BOTTOM_TAB_NAVIGATOR + 16
  }

  /**
   * 버튼 스크롤 탑 위치를 따로 지정해야하는 페이지
   */
  const hasLocalButtonScrollTop = pathname === ROUTES.EVENT_DETAIL || pathname === ROUTES.HOME.EVENTS.DETAIL

  return (
    <StyledFixedContainer bottom={getBottom()}>
      {pathname === ROUTES.INDEX && <ButtonRecentlyItem showTopButton={showTopButton} />}
      {!hasLocalButtonScrollTop && <ButtonScrollTop isAppToggle={isAppToggle} showTopButton={showTopButton} />}
    </StyledFixedContainer>
  )
}

const StyledFixedContainer = styled.div<{ bottom: number }>`
  z-index: ${Z_INDEXES.SCROLL_TOP_BUTTON};
  position: fixed;
  bottom: ${({ bottom }) => `calc(env(safe-area-inset-bottom) + ${bottom}px)`};

  // pc 기본
  right: calc(50vw - 576px + 4rem + 20px);
  @media (max-width: ${SCREEN_SIZES.lg}px) {
    right: calc(50vw - 288px + 20px);
  }
  @media ${SCREEN_MEDIA_QUERY.xs} {
    right: 20px;
  }
  display: flex;
  gap: 0.8rem;
  align-items: flex-end;
  flex-direction: column;
  pointer-events: none;

  div {
    pointer-events: auto;
  }
`
