import styled from 'styled-components'
import { Image, Text } from '@/components/common'
import { ProductThumbnailSizeEnum } from '@/constants/product-thumbnail-size.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { textEllipsis } from '@/utils/utilCSS'
import { getThumbnailPlaceHolder } from '@/utils/utilURL'
import type { BrandResponseDto } from '@/apis/rest/generated/types'

type BrandCircleListProps = {
  brand: BrandResponseDto
  selectedBrandId: number | undefined
  onSelectBrand: (brand: BrandResponseDto) => void
}

// TODO 2024-07-12 까지 brand image 수정 필요
export const BrandCircleList = ({ brand, selectedBrandId, onSelectBrand }: BrandCircleListProps) => {
  const tracker = useTracker()
  const { id, name, listImage } = brand

  const clickBrandCircleHandler = () => {
    onSelectBrand(brand)
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeBrandshopItem, {
      brandId: id,
      brandName: name,
      type: 'Brand',
    })
  }

  return (
    <StyledContainer onClick={clickBrandCircleHandler}>
      <StyledImageBorder className={id === selectedBrandId ? 'selected' : ''}>
        <StyledImageContainer>
          <Image
            alt="Brand Logo"
            src={listImage ?? `${process.env.HOST}/images/product-placeholder.svg`}
            layout="fill"
            objectFit="cover"
            blurDataURL={getThumbnailPlaceHolder(ProductThumbnailSizeEnum.Card136)}
            unoptimized // 브랜드 로고 이미지 사이즈가 크지 않기 때문에 width, height 지정하지 않고 unoptimized 로 사용
          />
        </StyledImageContainer>
      </StyledImageBorder>
      <StyledText>
        <span className={id === selectedBrandId ? 'selected' : ''}>{name}</span>
      </StyledText>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 7.2rem;
  row-gap: 0.8rem;
  cursor: pointer;
  box-sizing: border-box;
`

const StyledImageBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.8rem;
  height: 6.8rem;
  border: 0.2rem solid transparent;
  border-radius: 50%;

  &.selected {
    border-color: ${(props) => props.theme.color.blue500};
  }
`

const StyledImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0.1rem solid rgba(0, 0, 0, 0.03);
    border-radius: 50%;
  }

  img {
    border-radius: 50%;
  }
`

const StyledText = styled(Text.Body4)`
  ${textEllipsis(1)};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  .selected {
    color: ${(props) => props.theme.color.blue500};
    font-weight: ${(props) => props.theme.fontWeight.medium};
  }
`
