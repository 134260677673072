import { useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { getGetProductsByBrandIdQueryKey, useGetProductsByBrandIdV2 } from '@/apis/rest/generated/hooks'
import { BrandResponseDto, HomeSectionBrandResponseDto } from '@/apis/rest/generated/types'
import { StyledDivider } from '@/components/common/divider'
import { ScrollContainer } from '@/components/domains/etc'
import { HomeSectionTitle } from '@/components/domains/home'
import { Anchor } from '@/components/domains/home/Anchor'
import { BrandCircleList } from '@/components/domains/home/new/brand-circle-list'
import { NewProductCard } from '@/components/domains/products/new-product-card/new-product-card'
import {
  PRODUCT_CARD_SIZES,
  THUMBNAIL_STATES,
} from '@/components/domains/products/new-product-card/new-product-card.type'
import SkeletonProduct from '@/components/domains/products/skeleton/SkeletonProduct'
import { CardLayoutEnum } from '@/constants/card-layout.enum'
import { HomeSectionTitleEnum } from '@/constants/home-section-title.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { LocationEnum } from '@/constants/location.enum'
import { MinCountForScrollContainerEnum } from '@/constants/min-count-for-scroll-container.enum'
import { PAGE_LIMIT } from '@/constants/pages.const'
import { ProductSkeletonLengthEnum } from '@/constants/product-skeleton-length.enum'
import { ProductThumbnailSizeEnum } from '@/constants/product-thumbnail-size.enum'
import { useHomeBrandShopTracker } from '@/containers/event-tracker/home-brand-shop.tracker'
import { useHomeSectionProductTracker } from '@/containers/event-tracker/home-section-product.tracker'
import { AdPetType } from '@/graphql/generated/schemas'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { Base64ConverterUtil } from '@/utils/base64-converter.util'
import { transformProductTypeDefinedByFrontend } from '@/utils/product/product-card.util'
import { removeScrollbar } from '@/utils/utilCSS'
import HomeButtonSeeMore from '../HomeButtonSeeMore'

interface HomeSectionBrandProps {
  petType: AdPetType
  homeSectionItem: HomeSectionBrandResponseDto
  refCallback: (node: HTMLDivElement) => void
}

export const HomeSectionBrand = ({ petType, homeSectionItem, refCallback }: HomeSectionBrandProps) => {
  if (!homeSectionItem?.brands || homeSectionItem.brands.length === 0) {
    return null
  }
  if (!homeSectionItem?.products || homeSectionItem.products.length === 0) {
    return null
  }

  const { trackClickHomeSectionProductEvent } = useHomeSectionProductTracker()
  const { trackClickHomeBrandShopItemEvent } = useHomeBrandShopTracker()

  const { color } = useTheme()

  const [selectedBrand, setSelectedBrand] = useState(homeSectionItem.brands[0])
  const containerRef = useRef<HTMLDivElement | null>()

  const { data: productList, isLoading } = useGetProductsByBrandIdV2(
    selectedBrand.id,
    {
      size: PAGE_LIMIT.HOME_BRAND,
      page: PAGE_LIMIT.DEFAULT_PAGE,
      includeSoldOut: false,
      petType,
    },
    {
      query: {
        queryKey: [
          getGetProductsByBrandIdQueryKey(selectedBrand.id, {
            petType,
          }),
          selectedBrand.id,
        ],
        enabled: !!selectedBrand.id && !!petType,
      },
    }
  )

  const handleSelectBrand = (brand: BrandResponseDto) => {
    setSelectedBrand(brand)
  }

  const handleClickProduct = (product: ProductTypeDefinedByFrontend) => {
    if (!product) {
      return
    }
    trackClickHomeSectionProductEvent({
      product,
      sectionName: HomeSectionTitleEnum.BrandShop,
    })
    trackClickHomeBrandShopItemEvent({ brandId: selectedBrand.id, brandName: selectedBrand.name, type: 'Item' })
  }

  return (
    <div key={petType}>
      <Anchor id={HomeSectionTitleEnum.BrandShop} refCallback={refCallback} />
      <div>
        <HomeSectionTitle>브랜드 샵</HomeSectionTitle>
        <ScrollContainer buttonPositionY={30} isCircleType>
          <StyledContent ref={(r) => (containerRef.current = r)}>
            {homeSectionItem.brands.map((brand) => (
              <BrandCircleList
                key={brand.id}
                brand={brand}
                selectedBrandId={selectedBrand.id}
                onSelectBrand={handleSelectBrand}
              />
            ))}
          </StyledContent>
        </ScrollContainer>
        <ScrollContainer
          hideRightButton={homeSectionItem.products.length < MinCountForScrollContainerEnum.MainHomeSectionProduct}
          buttonPositionY={30}
        >
          <StyledListContainer>
            {isLoading && (
              <SkeletonProduct
                type={CardLayoutEnum.Vertical}
                length={ProductSkeletonLengthEnum.Four}
                size={ProductThumbnailSizeEnum.Card136}
              />
            )}
            {productList?.data?.map((product, index) => {
              const productData = transformProductTypeDefinedByFrontend(product)
              return (
                <NewProductCard
                  key={product.id}
                  product={productData}
                  size={PRODUCT_CARD_SIZES.lg}
                  thumbnailState={THUMBNAIL_STATES.basicLabel}
                  rankingNumber={index}
                  onClickProductCard={() => handleClickProduct(productData)}
                />
              )
            })}
          </StyledListContainer>
        </ScrollContainer>
        <HomeButtonSeeMore
          text={selectedBrand.name}
          seeMoreLink={{
            pathname: ROUTES.BRAND_DETAIL,
            query: {
              brandId: Base64ConverterUtil.convertNumberIdToBase64ByKey({ key: 'BrandType', id: selectedBrand.id }),
              location: LocationEnum.GnbHome,
            },
          }}
        />
      </div>
      <StyledDivider height="0.1rem" backgroundColor={color.gray70} />
    </div>
  )
}

const StyledContent = styled.div`
  position: relative;
  display: flex;
  column-gap: 0.4rem;

  & > div:first-child {
    margin-left: 16px;
  }
`
const StyledListContainer = styled.div`
  ${removeScrollbar};
  display: flex;
  column-gap: 0.8rem;
  margin-top: 1.6rem;

  & > *:first-child {
    margin-left: 16px;
  }

  & > *:last-child {
    padding-right: 16px;
  }
`
