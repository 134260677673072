import { useRef, useState } from 'react'
import Slider from 'react-slick'
import styled, { useTheme } from 'styled-components'
import { HomeSectionCategoryResponseDto } from '@/apis/rest/generated/types'
import { CarouselBaseV2 } from '@/components/common/carousels/CarouselBaseV2'
import { StyledDivider } from '@/components/common/divider'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { CategoryItemList } from '@/components/domains/home/new/home-section-list/category-section/category-item-list'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'

interface HomeSectionCategoryProps {
  homeSectionItem: HomeSectionCategoryResponseDto
}

export const HomeSectionCategoryA = ({ homeSectionItem }: HomeSectionCategoryProps) => {
  if (!homeSectionItem?.categories || homeSectionItem.categories.length === 0) {
    return null
  }
  if (!homeSectionItem.categories[0].children || homeSectionItem.categories[0].children.length === 0) {
    return null
  }

  const rootCategory = homeSectionItem.categories[0].children
  const [selectedRootCategoryId2, setSelectedRootCategoryId2] = useState(rootCategory[0].id)

  const rootCategoryIndex = rootCategory.findIndex((category) => category.id === selectedRootCategoryId2)
  const selectedRootCategory = rootCategory[rootCategoryIndex]

  const { color } = useTheme()
  const tracker = useTracker()
  const sliderRef = useRef<Slider>(null)

  const [isOpen, setIsOpen] = useState(false)

  // 홈 카테고리 사료 탭에서 샘플 제외 - Soi 요청
  // 홈 카테고리에서만 제외하고 카테고리 페이지에서는 출력됨
  const carouselData = rootCategory.map((category) => {
    return {
      id: category.id,
      name: category.name,
      subCategories:
        category.children?.map((productCategory) => productCategory).filter((edge) => edge?.slug !== 'sample') || [],
    }
  })

  const handleSwipeChange = (actionType: 'left' | 'right') => {
    let shouldChangeIndex
    if (actionType === 'right') {
      shouldChangeIndex =
        rootCategoryIndex - 1 < 0 ? rootCategoryIndex + rootCategory.length - 1 : rootCategoryIndex - 1
    } else {
      shouldChangeIndex = (rootCategoryIndex + 1) % rootCategory.length
    }
    setSelectedRootCategoryId2(rootCategory[shouldChangeIndex].id)
  }

  const handleCategorySelected = (selectedId: number) => {
    setIsOpen(false)
    const rootCategoryIndex = rootCategory.findIndex((category) => category.id === selectedId)
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(rootCategoryIndex)
    }
    const categoryTypeName = rootCategory.find((item) => item.id === selectedId)?.name
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeCategoryTab, { categoryTypeName })
    setSelectedRootCategoryId2(selectedId)
  }

  const handleOpenSeeMore = () => {
    setIsOpen(true)
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeCategoryItem, {
      categoryName: '전체보기',
    })
  }

  return (
    <>
      <StyledCategoryWrapper>
        <TabContainer>
          {rootCategory.map(({ id, name }) => {
            const isSelected = selectedRootCategoryId2 === id
            return (
              <TabItem key={id} isSelected={isSelected} onClick={() => handleCategorySelected(id)}>
                <Typo
                  variant={isSelected ? TypoVariant.Body3Bold : TypoVariant.Body3Medium}
                  color={isSelected ? color.blue500 : color.gray500}
                >
                  {name}
                </Typo>
              </TabItem>
            )
          })}
        </TabContainer>
        <CarouselBaseV2
          ref={sliderRef}
          onSwipe={handleSwipeChange}
          slidesToShow={1}
          lazyLoad
          NextArrow={<></>}
          PreviousArrow={<></>}
          speed={200}
        >
          {carouselData.map((rootCategory) => (
            <StyledList key={rootCategory.id}>
              {rootCategory.subCategories
                ?.slice(0, isOpen ? rootCategory.subCategories.length : rootCategory.subCategories.length > 10 ? 9 : 10)
                .map((subCategory) => (
                  <CategoryItemList
                    key={subCategory.id}
                    subCategory={subCategory}
                    selectedRootCategory={selectedRootCategory}
                  />
                ))}
              {rootCategory.subCategories.length > 10 && !isOpen && (
                <SeeMoreCol onClick={handleOpenSeeMore}>
                  <StyledSeeMoreItem>
                    <StyledImageRow>
                      <FlatIcon type={IconNameEnum.IcArrowDown} size={IconSizeEnum.Size24} />
                    </StyledImageRow>
                  </StyledSeeMoreItem>
                  <StyledTextRow>
                    <Typo variant={TypoVariant.Body5Regular}>더 보기</Typo>
                  </StyledTextRow>
                </SeeMoreCol>
              )}
            </StyledList>
          ))}
        </CarouselBaseV2>
      </StyledCategoryWrapper>
      <StyledDivider height="0.8rem" backgroundColor={color.gray70} />
    </>
  )
}

const StyledCategoryWrapper = styled.div`
  margin: 1.6rem 0;
`

const StyledList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 20%);
  grid-row-gap: 1.2rem;
  margin-top: 1.6rem;
  padding: 0 1.2rem;
  min-height: 15rem;
`

const SeeMoreCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledSeeMoreItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  background-color: #f1f1ed;
  cursor: pointer;
`

const StyledImageRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledTextRow = styled.div`
  margin-top: 0.4rem;
  display: flex;
  justify-content: center;

  div {
    text-align: center;
    letter-spacing: -0.02em;
    color: ${(props) => props.theme.color.gray700};
  }
`

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.4rem;
`

const TabItem = styled.div<{ isSelected: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.size6};
  padding: 0.6rem 1.4rem;
  cursor: pointer;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ isSelected, theme }) => (isSelected ? theme.color.blue500 : 'transparent')};
    border-radius: 2.4rem;
  }
`
