import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'

export const useHomeBrandShopTracker = () => {
  const tracker = useTracker()

  const trackClickHomeBrandShopItemEvent = ({
    brandId,
    brandName,
    type,
  }: {
    brandId?: number
    brandName?: string
    type: string
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeBrandshopItem, {
      brandId,
      brandName,
      type,
    })
  }
  return { trackClickHomeBrandShopItemEvent }
}
